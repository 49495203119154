<template>
    <!-- eslint-disable -->
    <Form :action="action" :labelVal="label" :id="id" :dataProvinsiVal="dataProvinsi" :provinsiVal="provinsi" :isShowKotaKabupatenVal="isShowKotaKabupaten" :kotaKabupatenVal="kotaKabupaten" :dataKotaKabupatenVal="dataKotaKabupaten" />
</template>

<script>
import Form from './Form.vue'
import {ref} from '@vue/composition-api'

export default {
    setup(props, context){
        const g = context.root

        const $http = g.$http

        var label = ref('')
        var provinsi = ref('')
        var kotaKabupaten = ref('')
        var dataProvinsi = ref([])
        var dataKotaKabupaten = ref([])
        var isShowKotaKabupaten = ref(false)

        const getData = () => {
            $http({
                url: '/mst/daerah/kecamatan/ubah/'+props.id,
                headers: {
                    Authorization: 'Bearer '+g.$cookies.get('token')
                }
            })
                .then(res => {
                    const {labelVal, provinsiVal, kotaKabupatenVal, dataKecamatan} = res.data.data
                    label.value = labelVal
                    provinsi.value = provinsiVal
                    kotaKabupaten.value = kotaKabupatenVal
                    dataProvinsi.value = dataKecamatan.dataProvinsiVal
                    dataKotaKabupaten.value = dataKecamatan.dataKotaKabupatenVal

                    if(provinsiVal)
                        isShowKotaKabupaten.value = true
                })
                .catch(err => {
                    let showError = err.response ? err.response.data : err.request ? err.request : 'Error' + err.message;

                    g.$bvToast.toast(showError || 'Something Wrong', {
                        title: `Error Load Data`,
                        variant: 'danger',
                        solid: true,
                    })
                })
        }

        getData()

        return {
            label, provinsi, dataProvinsi, isShowKotaKabupaten, kotaKabupaten, dataKotaKabupaten
        }
    },
    data(){
        return{
            action: 'Ubah'
        }
    },
    components: {
        Form
    },
    props: ['id']
}
</script>